/* font-family */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* theme */
:root {
  --white: #ffffff;
  --black: #000000;
  --color-theme-1: #546E7A;
  --color-theme-2: #F06292;
  --color-theme-3: #51C4D3;
  --color-theme-4: rgba(84, 110, 122, 0.2);
  --color-theme-5: rgba(298, 98, 146, 0.2);
  --font-family-theme: Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
input, button,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

html {
  font-size: 16px;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000000;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto Light", arial, serif;
  line-height: 1.5;
}